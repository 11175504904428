<template>
    <div class="p-relative">
        <!-- Тулбар -->
        <div class="top-btns-bar">
            <!--
            <div class="transparent-buttons">
                <v-btn small tile text>
                    <v-icon left>far fa-paper-plane</v-icon>
                    Экспорт_списка
                </v-btn>
            </div>
            -->
            <!--Панель поиска  v-model="search"
            <SearchPanel
                :has-filter="false"
            />-->
        </div>

        <!--Календарь совещаний-->
        <EventsCalendar />
       
    </div>
</template>

<script>

import EventsCalendar from '@/components/meetings/Calendar.vue';

export default {
    name: "MeetingsCalendar",
    components: {
        EventsCalendar
    },
}

</script>